import React, { Component } from 'react';
import Login from './ui/login/login';
import Sherlook from './ui/sherlook/sherlook';

import Registracia from './ui/register/register';
import RegistraciaDlznik from './ui/register/registerDebtor';
import RegistraciaInvestor from './ui/register/registerInvestor';
import './ui/login/login.css';
import { BrowserRouter } from 'react-router-dom';
import { Route } from 'react-router-dom'
import DB from './util/db/db';

import {connect} from 'react-redux'
import * as actionTypes from './store/actions'

import axios from 'axios'

//axios.defaults.baseURL = 'http://localhost:3000/';
axios.defaults.baseURL = 'https://light.sherlook.sk/';


class App extends Component {

  state = {
    auth: false,
    lang: "sk",
    company: "",
    token: "",
    user: "",
    subject: ""
  }

  constructor(props) {
    super(props);

    this.state.token = localStorage.getItem("token");
    DB.token = this.state.token;
    
    if(this.state.token !== "")
    {
    }

    //this.state.company = localStorage.getItem("company");
    let company = localStorage.getItem("company");
    if(company !== "")
    {
      this.state.company = company;
      DB.company = this.state.company;
    }
  }

  loginHandler(params)
  {
    //console.log("loginHandler", params);

    if(params === undefined)
    {
      params = {company: "", user: "", subject: "", token: ""}
    }

    if (typeof(Storage) !== "undefined") {
      localStorage.setItem("token", params.token);
      localStorage.setItem("company", params.company);
    } else {
      // Sorry! No Web Storage support..
    }
    
    DB.token = params.token;
    DB.company = params.company;
    DB.db_structure = params.db_structure;

    this.setState(
      {
        ...this.state,
        company: params.company,
        user: params.user,
        subject: params.subject,
        token: params.token,
        auth: true
      }
    )

    this.props.setUser(params);

    //console.log("setUser", this.props.setUser);
  }

  logoutHandler()
  {

    localStorage.setItem("token", "");
    //localStorage.setItem("company", "");

    this.setState(
      {
        ...this.state,
        auth: false,
        token: ""
      }
    )
  }

  render()
  {

    let loginPaths = []
    loginPaths.push("/");
    let URIComponent = "";

    if(this.state.company !=="") 
    {
      URIComponent = "?company=" + this.state.company;
      loginPaths.push("/login" + URIComponent);
      //loginPaths.push("/" + URIComponent);
    }

    //loginPaths.push("https://light.sherlook.sk/login");
    loginPaths.push("/login");
    loginPaths.push("/");

    //console.log("props", this.props.user);

    return (
      <BrowserRouter>
        <Route path={loginPaths} exact component={() => <Login token={this.state.token}  lang={this.state.lang} auth={this.state.auth} loginHandler={(params) => this.loginHandler(params)} />} />
        <Route path='/application' exact component={() => <Sherlook user={this.state.user} subject={this.state.subject} lang={this.state.lang} company={this.state.company} auth={this.state.auth} logoutHandler={() => this.logoutHandler()}/>} />
        <Route path='/register' exact component={() => <Registracia modal={true} />} />
        <Route path='/registracia/dlznik' exact component={() => <RegistraciaDlznik modal={true} />} />
        <Route path='/registracia/investor' exact component={() => <RegistraciaInvestor modal={true} />} />
      </BrowserRouter>
    );
  }


}

//this.props.user is now available
//this.props.subject is now available
//...

const mapStateToProps = state => {
  return{
    auth: state.auth,
    user: state.user,
    subject: state.subject,
    company: state.company,
  }
};

//you can call: this.props.someFunctionName, in this cas this.props.setUser

const mapDispatchToProps = dispatch => {
  return {
    setUser: (params) => dispatch ({
      type: actionTypes.SET_USER,
      payload: {
        auth: params.auth,
        user: params.user,
        subject: params.subject,
        subject_case: params.subject_case,
        company: params.company
      }
    })
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(App);
