import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import logo from './../resources/najcredit.svg';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from "@material-ui/core/styles";
import IBAN from 'iban';
import { rodnecislo } from 'rodnecislo';
import isNumeric from '../../util/isNumeric';

import axios from 'axios'
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';


//import MomentUtils from 'moment';
import MomentUtils from '@date-io/moment';
import moment from 'moment'
import "moment/locale/sk";

import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const useStyles = theme => ({
  root: {
    backgroundColor: "transparent",
    backgroundImage: "linear-gradient(135deg, #1B8AF1, #d8deeb)"    //backgroundImage: "linear-gradient(to right, #1B8AF1, #d8deeb)"
   },
});

const zoznamTitulov = [
    { titul: 'Dr.', popis: 'doktor'},
    { titul: 'Bc.', popis: 'bakalár'},
    { titul: 'MUDr.', popis: 'doktor všeobecného lekárstva'},
    { titul: 'MVDr.', popis: 'doktor veterinárskeho lekárstva'},
    { titul: 'MDDr.', popis: 'doktor zubného lekárstva'},
    { titul: 'Ing.', popis: 'inžinier'},
    { titul: 'Ing. arch.', popis: 'inžinier architekt'},
    { titul: 'Mgr.', popis: 'magister'},
    { titul: 'Mgr. art.', popis: 'magister umenia'},
    { titul: 'BSc.', popis: 'Joint Bachelor Degree in Animal Science'},
    { titul: 'MSc.', popis: 'Master of Science'},
    { titul: 'JUDr.', popis: 'doktor práv'},
    { titul: 'RNDr.', popis: 'doktor prírodných vied'},
    { titul: 'PhDr.', popis: 'doktor filozofie'},
    { titul: 'PaedDr.', popis: 'doktor pedagogiky'},
    { titul: 'PharmDr.', popis: 'doktor farmácie'},
    { titul: 'ThDr.', popis: 'doktor teológie'},
    { titul: 'ThLic.', popis: 'licenciát teológie'},
    { titul: 'PhMr.', popis: 'magister farmácie'},
    { titul: 'prom. biol.', popis: 'promovaný biológ'},
    { titul: 'prom. chem.', popis: 'promovaný chemik'},
    { titul: 'prom. ek.', popis: 'promovaný ekonóm'},
    { titul: 'prom. pharm.', popis: 'promovaný farmaceut'},
    { titul: 'prom. filol.', popis: 'promovaný filológ'},
    { titul: 'prom. fil.', popis: 'promovaný filozof'},
    { titul: 'prom. fyz.', popis: 'promovaný fyzik'},
    { titul: 'prom. geog.', popis: 'promovaný geograf'},
    { titul: 'prom. geol.', popis: 'promovaný geológ'},
    { titul: 'prom. hist.', popis: 'promovaný historik'},
    { titul: 'prom. knih.', popis: 'promovaný knihovník'},
    { titul: 'prom. logop.', popis: 'promovaný logopéd'},
    { titul: 'prom. mat.', popis: 'promovaný matematik'},
    { titul: 'prom. nov.', popis: 'promovaný novinár'},
    { titul: 'prom. ped.', popis: 'promovaný pedagóg'},
    { titul: 'prom. práv.', popis: 'promovaný právnik'},
    { titul: 'prom. psych.', popis: 'promovaný psychológ'},
    { titul: 'prom. vet.', popis: 'promovaný veterinársky lekár'},
    { titul: 'prom. zub.', popis: 'promovaný zubár'},
    { titul: 'arch.', popis: 'architekt'},
    { titul: 'RSDr.', popis: 'doktor sociálno-politických vied'},
    { titul: 'akad. arch.', popis: 'akademický architekt'},
    { titul: 'akad. mal.', popis: 'akademický maliar'},
    { titul: 'akad. soch.', popis: 'akademický sochár'},
    { titul: 'doc.', popis: 'docent'},
    { titul: 'prof.', popis: 'profesor'}
]

const initialState = {
    titul: "",
    meno: "",
    priezvisko: "",
    datum_narodenia: "",
    cop: "",
    rodne_cislo: "",
    iban: "",
    ulica: "",
    mesto: "",
    user_email: "",
    tel: "",
    psc: "",
    doba_uveru_v_mesiacoch: 1,
    istina: "",
    zamestnavatel: "",
    zamestnavatel_mesto: "",
    pocet_exekucii: "0",
    suhlas_obch_podmienky: false,
    suhlas_spracovanie_udajov: false,
    showProgress: false,
    registerMessage: "",
    registered: true,
    role: "klient"
};

const defaultProps = {
    options: zoznamTitulov,
    getOptionLabel: (option) => option.titul,
};

class Registracia extends Component {
  
  constructor(props){
    super(props)

    this.submitHandler = props.submitHandler

    this.state = {
      ...initialState,
      modal: props.modal,
    }

    let m = moment(new Date()).subtract(18, 'years');
    this.state.datum_narodenia = m.format("YYYY-MM-DD");
    this.max_datum_narodenia = m.format("YYYY-MM-DD");

    let keys = Object.keys(initialState);
    for (var index in keys) {
        let key = keys[index];
        this.state[key + "Error"] = false;
        this.state[key + "HelperText"] = "";
    }

    if(props.data !== undefined)
    {
      let keys = Object.keys(initialState);

      for (var index in keys) {
        let key = keys[index];
        if (props.data[key] === undefined) continue;

        this.state[key] = props.data[key];
      }
 
    }
  }
 
  register()
  {
    axios('/backend/register.php',
    {
      method: 'POST',
      mode: 'no-cors',
      data:{
        ...this.state,
        company: "datys",
        role: "klient"
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        //'Content-Type': 'application/json',
        "Content-Type": "text/html;charset=UTF-8"
      },
      withCredentials: false,
      credentials: 'same-origin',
    })
    .then(response => {  

      if(!response.data.sucess)
      {
        throw response.data.message;
      }

      let newState = {...initialState}
      let keys = Object.keys(initialState);
      for (var index in keys) {
          let key = keys[index];
          newState[key + "Error"] = false;
          newState[key + "HelperText"] = "";
      }

      //newState = {};//just test
      newState["showProgress"] = false;
      newState["registerMessage"] = "Vaša registrácia bola úspešne vykonaná. Zaslali sme Vám email s dalšími inštrukciami."

      this.setState(newState);

      //if(!this.state.rememberMe) params.token = "";
      //this.props.loginHandler(params);
    })
    .catch(error => {
      const message = error + "";

      this.setState(
        {
          ...this.state,
          showProgress: false,
          registerMessage: message
        }
      );
    }); 

  }

  validateField(event, key, value)
  {

    if(event !==undefined)
    {
      key = event.target.name;
      value = event.target.value;
    }

    //suhlas_obch_podmienky
    //suhlas_spracovanie_udajov

    if(key === "suhlas_obch_podmienky")
    {
      if (!value){
        return "suhlas_obch_podmienky";
      }
    }

    if(key === "suhlas_spracovanie_udajov")
    {
      if (!value){
        return "suhlas_spracovanie_udajov";
      }
    }

    if(key === "datum_narodenia")
    {

    }

    if(key === "meno")
    {
      if (!value){
        return "Nezadané meno";
      }
    }

    if(key === "priezvisko")
    {
      if (!value){
        return "Nezadané priezvisko";
      }
    }

    if(key === "ulica")
    {
      if (!value){
        return "Nezadaná ulica";
      }
    }

    if(key === "mesto")
    {
      if (!value){
        return "Nezadané mesto";
      }
    }

    if(key === "psc")
    {
      if (!value){
        return "Nezadané PSČ";
      }
      else if(this.validatePSC(value)===false){
        return "Neplatný formát psč";
      }
    }

    if(key === "tel")
    {
      if (!value){
        return "Nezadaný telefón";
      }
      else if(this.validateTel(value)===false){
        return "Nesprávny formát čísla";
      }
    }

    if(key === "user_email")
    {
      if (!value){
        return "Nezadaný email";
      }
      else if(this.validateEmail(value)===false){
        return "Email je v zlom formáte";
      }
    }

    if(key === "iban")
    {
      if (!value){
        return "Nezadaný iban";
      }
      else if(IBAN.isValid(value)===false){
        return "IBAN je v zlom formáte";
      }
    }

    if(key === "cop")
    {
      if (!value){
        return "Nezadané číslo občianskeho preukazu";
      }
    }

    if(key === "rodne_cislo")
    {
      if (!value){
        return "Nezadané rodné číslo";
      }
      else if (!rodnecislo(value).isValid()) {
        return "Rodné číslo nie je validné";
      }
    }

    if(key === "zamestnavatel")
    {
      if (!value){
        return "Nezadaný zamestnávateľ";
      }
    }

    if(key === "zamestnavatel_mesto")
    {
      if (!value){
        return "Nezadané mesto zamestnávateľa";
      }
    }

    if(key === "pocet_exekucii")
    {
      if (!value){
        return "Nezadaný počet exekúcií";
      }
    }

    if(key === "istina")
    {
      if (!value){
        return "Nezadaná výška pôžičky";
      }
      else
      {
        let r = value.replace(/,/g, '.');
        
        if(!isNumeric(r))
        {
          return "Výška pôžičky nie je spravnom formáte";
        }
        else{
          let n = parseFloat(r);
          if(n > 200 ) return "Maximálna výška pôžičky je 200 €";
        }
  
        
      }
    }

    return "";
  }

  validate = (event) => {

    let key = undefined;
    let value = undefined;

    if(event !==undefined)
    {
      key = event.target.name;
      value = event.target.value;

      let result = this.validateField(event);

      let newState = {};
      newState[key + "Error"] = false;
      
      if(result !== "")
      {
        newState[key + "Error"] = true;
        newState[key + "HelperText"] = result;
      }

      this.setState(newState);

      return;
    }

    let isValid = true;
    
    let keys = Object.keys(this.state);
    let newState = {...this.state};

    for (var index in keys) {
      let key = keys[index];
      newState[key + "Error"] = false;

      let result = this.validateField(undefined, key, this.state[key]);

      if(result !== "")
      {
        newState[key + "Error"] = true;
        newState[key + "HelperText"] = result;

        isValid = false;
      }
      
    }

    this.setState(newState);

    return isValid;

  }

  validateTel = tel => {
    const reTelefon = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    let isValid = reTelefon.test(tel);
    return isValid;
  }
  
  validatePSC = psc => {
    const rePSC = /^\d{5}$/;
    return rePSC.test(psc);
  }
  
  validateteDic = dic => {
    const reDic = /^[A-Z]{2}\d{10}$/;
    return reDic.test(dic);
  }

  validateEmail = email => {
    const reEmail = /\S+@\S+\.\S+/;
    return reEmail.test(email);
  }
    
  handleSubmit = event => {
    event.preventDefault();

    let isValid = true;
    isValid = this.validate();
    //let isValid = true;//for testing
    
    if (isValid) {

      //console.log('everything ok');

      let data = {};
      let keys = Object.keys(initialState);
      for (var index in keys) {
        let key = keys[index];
        data[key] = this.state[key];
      }

      //showProgress: false
      this.setState({"showProgress": true}, this.register);

      if(this.submitHandler) 
      {
        this.submitHandler(data);
      }
      else
      {
        //ajax call to register
        //post data
      }
    }
    else
    {
      //alert("chyba");
      console.log("error handleSubmit");
    }
  };

  handleChange = event => {

    let value = "";
    if(moment.isMoment(event))
    {
      var momentObj = event;
      let value = momentObj.format("YYYY-MM-DD");

      this.setState({
        datum_narodenia: value
      });

      return;
    }

    if(event.target.type === "checkbox") value =  event.target.checked;
    else value = event.target.value;
    //event.target.type === "checkbox" ? e.target.checked : e.target.value

    this.setState({
      [event.target.name]: value
    });
  };

  render(){

    const { classes } = this.props;

    if(this.state.registerMessage !=="")
    {
      return(
        <div>
        <Dialog open={true}  aria-labelledby="form-dialog-title"
          BackdropProps={{
            classes: {
              root: classes.root
            }
          }}
        >
        <DialogTitle id="customized-dialog-title">
            <Grid container alignItems="center" spacing={0}>
                <Grid item xs={12} sm={6}><img src={logo} className="App-logo" alt="logo" /></Grid>
            </Grid>
        </DialogTitle>

          <DialogContent>

            <div>{this.state.registerMessage}</div>
            <br></br>

          </DialogContent>

          <DialogActions>
          <Button onClick={()=>{this.setState({registerMessage: "", showProgress: false})}} color="primary">
            Nová registrácia
          </Button>
        </DialogActions>

        </Dialog>
      </div>
      )
    }

    if(this.state.showProgress)
    {
      return(
        <div>
        <Dialog open={true}  aria-labelledby="form-dialog-title"
          BackdropProps={{
            classes: {
              root: classes.root
            }
          }}
        >
        <DialogTitle id="customized-dialog-title">
            <Grid container alignItems="center" spacing={0}>
                <Grid item xs={12} sm={6}><img src={logo} className="App-logo" alt="logo" /></Grid>
            </Grid>
        </DialogTitle>

          <DialogContent>

            <div>Prihlasujem do systému...</div>
            <br></br>

            <CircularProgress />

          </DialogContent>
        </Dialog>
      </div>
      )
    }

    
    
    let content = (<div>
              <Grid container spacing={2}>

                <Grid item sm={12} xs={12}>
                        <Autocomplete
                        {...defaultProps}
                        id="flat-demo"
                        renderInput={(params) => <TextField {...params} label="titul" margin="normal" />}
                    />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <TextField
                    error = {this.state.menoError}
                    name="meno"
                    value={this.state.meno}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    margin="dense"
                    id="meno"
                    label="Meno"
                    type="text"
                    required
                    fullWidth
                    helperText = {this.state.menoHelperText}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <TextField
                    error = {this.state.priezviskoError}
                    name="priezvisko"
                    value={this.state.priezvisko}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    margin="dense"
                    id="priezvisko"
                    label="Priezvisko"
                    type="text"
                    required
                    fullWidth
                    helperText = {this.state.priezviskoHelperText}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <TextField
                    error = {this.state.copError}
                    name="cop"
                    value={this.state.cop}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    margin="dense"
                    id="cop"
                    label="číslo op"
                    type="text"
                    required
                    fullWidth
                    helperText = {this.state.copHelperText}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <TextField
                    error = {this.state.rodne_cisloError}
                    name="rodne_cislo"
                    value={this.state.rodne_cislo}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    margin="dense"
                    id="rodne_cislo"
                    label="Rodné číslo (formát s lomítkom)"
                    type="text"
                    required
                    fullWidth
                    helperText = {this.state.rodne_cisloHelperText}
                  />
                </Grid>

                <MuiPickersUtilsProvider locale={"sk"} utils={MomentUtils}>
                <Grid item sm={6} xs={12}>
                  <DatePicker
                  helperText = {this.state.datum_narodeniaHelperText}
                  maxDate={new Date(this.max_datum_narodenia)}
                  format="DD.MM.yyyy"
                  label="Dátum narodenia"
                  name = "datum_narodenia"
                  value={this.state.datum_narodenia}
                  onChange={this.handleChange}
                  />
                </Grid>
                </MuiPickersUtilsProvider>

                <Grid item sm={6} xs={12}>
                  <TextField
                    error = {this.state.ibanError}
                    name="iban"
                    value={this.state.iban}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    margin="dense"
                    id="iban"
                    label="Číslo bankového účtu kam zašleme peniaze (formát IBAN):"
                    type="text"
                    required
                    fullWidth
                    helperText = {this.state.ibanHelperText}
                  />
                </Grid>

                <Grid item sm={12} xs={12}>
                    <InputLabel id="demo-simple-select-label">Na koľko mesiacov peniaze potrebujete?</InputLabel>
                    <Select
                    style = {{display: 'flex'}}
                    labelId="demo-simple-select-label"
                    id="doba_uveru_v_mesiacoch"
                    name="doba_uveru_v_mesiacoch"
                    value={this.state.doba_uveru_v_mesiacoch}
                    onChange={this.handleChange}
                    >
                    <MenuItem value={1}>1 mesiac</MenuItem>
                    <MenuItem value={2}>2 mesiace</MenuItem>
                    <MenuItem value={3}>3 mesiace</MenuItem>
                    <MenuItem value={4}>4 mesiace</MenuItem>
                    <MenuItem value={5}>5 mesiacov</MenuItem>
                    <MenuItem value={6}>6 mesiacov</MenuItem>
                    <MenuItem value={7}>7 mesiacov</MenuItem>
                    <MenuItem value={8}>8 mesiacov</MenuItem>
                    <MenuItem value={9}>9 mesiacov</MenuItem>
                    </Select>
                </Grid>

                <Grid item sm={12} xs={12}>
                  <TextField
                    error = {this.state.istinaError}
                    name="istina"
                    value={this.state.istina}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    margin="dense"
                    id="istina"
                    label="Koľko potrebujete? (max 200€):"
                    type="text"
                    required
                    fullWidth
                    helperText = {this.state.istinaHelperText}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    error = {this.state.ulicaError}
                    name="ulica"
                    value={this.state.ulica}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    required
                    margin="dense"
                    id="ulica"
                    label="Ulica a číslo domu:"
                    type="text"
                    fullWidth
                    helperText = {this.state.ulicaHelperText}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    error = {this.state.mestoError}
                    name="mesto"
                    value={this.state.mesto}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    required
                    margin="dense"
                    id="mesto"
                    label="Mesto"
                    type="text"
                    fullWidth
                    helperText = {this.state.mestoHelperText}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    error = {this.state.pscError}
                    name="psc"
                    value={this.state.psc}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    margin="dense"
                    id="psc"
                    label="PSČ"
                    type="text"
                    required
                    fullWidth
                    helperText = {this.state.pscHelperText}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    error = {this.state.telError}
                    name="tel"
                    value={this.state.tel}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    required
                    margin="dense"
                    id="tel"
                    label="Telefón ( 09xx xxx xxx )"
                    type="text"
                    fullWidth
                    helperText = {this.state.telHelperText}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    error = {this.state.user_emailError}
                    name="user_email"
                    value={this.state.user_email}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    required
                    margin="dense"
                    id="user_email"
                    label="E-mail"
                    type="email"
                    fullWidth
                    helperText = {this.state.user_emailHelperText}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <TextField
                    error = {this.state.zamestnavatelError}
                    name="zamestnavatel"
                    value={this.state.zamestnavatel}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    margin="dense"
                    id="zamestnavatel"
                    label="Názov zamestnávateľa:"
                    type="text"
                    required
                    fullWidth
                    helperText = {this.state.zamestnavatelHelperText}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <TextField
                    error = {this.state.zamestnavatel_mestoError}
                    name="zamestnavatel_mesto"
                    value={this.state.zamestnavatel_mesto}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    margin="dense"
                    id="zamestnavatel_mesto"
                    label="Mesto zamestnávateľa:"
                    type="text"
                    required
                    fullWidth
                    helperText = {this.state.zamestnavatel_mestoHelperText}
                  />
                </Grid>

                <Grid item sm={12} xs={12}>
                  <TextField
                    error = {this.state.pocet_exekuciiError}
                    name="pocet_exekucii"
                    value={this.state.pocet_exekucii}
                    onChange={this.handleChange}
                    onBlur={this.validate}
                    margin="dense"
                    id="pocet_exekucii"
                    label="Počet exekúcií  (údaj sa overuje, uvádzajte pravdivo):"
                    type="text"
                    required
                    fullWidth
                    helperText = {this.state.pocet_exekuciiHelperText}
                  />
                </Grid>

                <Grid item sm={12} xs={12}>
                <FormControl required error={!this.state.suhlas_obch_podmienky} component="fieldset" className={classes.formControl}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.suhlas_obch_podmienky}
                        onChange={this.handleChange}
                        name="suhlas_obch_podmienky"
                        color="primary"
                      />
                    }
                    label="Zaškrtnutím tohto poľa vyjadrujete súhlas s našimi obchodnými podmienkami."
                  />
                  {!this.state.suhlas_obch_podmienky ? <FormHelperText>toto pole je povinné</FormHelperText> : null}
                  
                  </FormControl>
                </Grid>

                <Grid item sm={12} xs={12}>
                <FormControl required error={!this.state.suhlas_spracovanie_udajov} component="fieldset" className={classes.formControl}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.suhlas_spracovanie_udajov}
                        onChange={this.handleChange}
                        name="suhlas_spracovanie_udajov"
                        color="primary"
                      />
                    }
                    label="Zaškrtnutím tohto poľa vyjadrujete súhlas s našim spracovaním vašich osobných údajov"
                  />
                  {!this.state.suhlas_spracovanie_udajov ? <FormHelperText>toto pole je povinné</FormHelperText> : null}
                  </FormControl>
                </Grid>

              </Grid>
            </div>)


  if (this.state.modal){    
    return (
      <Dialog open={true} maxWidth={"lg"} aria-labelledby="form-dialog-title"
        BackdropProps={{
          classes: {
            root: classes.root
          }
        }}
        >

        <DialogTitle id="customized-dialog-title">
            <Grid container alignItems="center" spacing={0}>
                <Grid item xs={12} sm={6}><img src={logo} className="App-logo" alt="logo" /></Grid>
                <Grid item xs={12} sm={6}><h2 className="reg">Registrácia</h2></Grid>
            </Grid>
        </DialogTitle>

        <DialogContent>
          {content}
        </DialogContent>

        <DialogActions>
          <Button onClick={this.handleSubmit} color="primary">
            Registrácia
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return(
      <DialogContent>
        {content}
      <DialogActions>
        <Button onClick={this.handleSubmit} color="primary">
          Ulož
        </Button>
      </DialogActions>
      </DialogContent>
    )
    }
  }
}

export default withStyles(useStyles)(Registracia)




