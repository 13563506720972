import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import  { Redirect } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Base64 } from 'js-base64';
import axios from 'axios'
import { withStyles } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

import findGetParameterInUrl from '../../util/findGetParameterInUrl'

import logoSherlook from './../resources/Sherlook.svg';
import logoNajcredit from './../resources/najcredit.svg';
import './login.css';

import Config from '../config/config'

const useStyles = theme => ({
  root: {
    backgroundColor: "transparent",
    backgroundImage: "linear-gradient(135deg, #1B8AF1, #d8deeb)"    //backgroundImage: "linear-gradient(to right, #1B8AF1, #d8deeb)"
   },
});

const initialState = {
  auth: false,
  showprogress: false,
  company: "",
  urlCompany: "",
  username: "",
  password: "",
  usernameError: "",
  passwordError: "",
  companyError: "",
  loginError: ""
};

class Login extends Component {

  constructor(props) {
    super(props);

    //http://localhost:3000/login?params=eyJjb21wYW55IjogInNoZXJsb29rLXRlc3QiLCAidXNlcm5hbWUiOiAiYWRtaW4iLCAicGFzc3dvcmQiOiAiYWRtaW4ifQ

    //redirect = false;
    //http://localhost:3000/login?params=eyJjb21wYW55IjogInNoZXJsb29rLXRlc3QiLCAidXNlcm5hbWUiOiAiYWRtaW4iLCAicGFzc3dvcmQiOiAiYWRtaW4iLCAicmVkaXJlY3QiOiBmYWxzZX0

    //http://localhost:3000/login?company=sherlook-test
    
    this.loginHandler = props.loginHandler
    let token = props.token;

    let company = findGetParameterInUrl("company");
    if(company === null) company = "";

    let host = window.location.hostname;
    var n = host.indexOf("najcredit");
    if(n > -1) company = "datys";

    let username = findGetParameterInUrl("username");
    if(username === null) username = "";

    let password = findGetParameterInUrl("password");
    if(password === null) password = "";

    let loginparams = findGetParameterInUrl("params");

    let redirect = true;

    if(loginparams !== null) 
    {
      let s = Base64.decode(loginparams);
      
      try {
        var obj = JSON.parse(s);

        username = obj.username;
        password = obj.password;
        company = obj.company;

        if(redirect in obj) redirect = obj.redirect;

      } catch(e) {
          console.log(e);
      }
    }    

    let rememberMe = true;
    let showprogress = false;
    if(company !== "" && username !== "" && password !== "")
    {
      showprogress = true;
    }
    
    if(token !== "") 
    {
      showprogress = true;
      rememberMe = true;
    }

    if(!redirect) showprogress = false;

    //if showprogress is true, login will start automatically - see componentDidMount

    this.state = {
      ...initialState,
      auth: props.auth,
      token: props.token,
      company: company,
      urlCompany: company,
      username: username,
      password: password,
      showprogress: showprogress,
      rememberMe: rememberMe,
      visibleRegisterButton: false,
      register: false
    }

  }
  
  componentDidMount() {

    if(this.state.company !=="") this.isRegisterAllowed();

    if(this.state.showprogress)
    {
      if(!this.state.auth)
      {
        this.login();
      }
    }
  }

  handleChange = event => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value
    });
  };

  handleChangeCheckBox = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  validate = () => {
    let usernameError = "";
    let passwordError = "";
    let companyError = "";

    if (!this.state.username){
      usernameError = "Nie je zadané prihlasovacie meno";
    }

    if (!this.state.password){
      passwordError = "Nie je zadané heslo";
    }

    if (!this.state.company){
      companyError = "Nie je zadaná firma";
    }

    if (usernameError || passwordError || companyError) {
      this.setState(
        {
          ...this.state,
          "usernameError": usernameError,
          "passwordError": passwordError,
          "companyError": companyError
        }
      );
      return false;
    }

    return true;
  }

  login()
  {
    axios('/backend/login.php',
    {
      method: 'POST',
      mode: 'no-cors',
      data:{
        company: this.state.company,
        username: this.state.username,
        password: this.state.password,
        token: this.state.token
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        //'Content-Type': 'application/json',
        "Content-Type": "text/html;charset=UTF-8"
      },
      withCredentials: false,
      credentials: 'same-origin',
    })
    .then(response => {  

      if(!response.data.sucess)
      {
        throw response.data.message;
      }

      let params = {
        token: response.data.token,
        company: this.state.company,
        user: {...response.data.user},
        subject: {...response.data.subject},
        subject_case: {...response.data.subject_case},
        db_structure: response.data.db_structure,
        app_version: response.data.app_version,
      }

        //--------------------------------------------
        Config.user = response.data.user;
        Config.subject = response.data.subject;
        Config.companies = response.data.companies;
        Config.enum_case_status = response.data.enum_case_status;
        //--------------------------------------------

      if(!this.state.rememberMe) params.token = "";
      this.props.loginHandler(params);
    })
    .catch(error => {
      const message = error + "";

      this.setState(
        {
          ...this.state,
          showprogress: false,
          auth: false,
          loginError: message
        }
      );
    }); 

  }

  isRegisterAllowed= event => {

      //load if register is allowed
      axios('/backend/get_settings.php',
      {
        method: 'POST',
        mode: 'no-cors',
        data:{
          company: this.state.company,
          request: "isRegisterAllowed"
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-Type": "text/html;charset=UTF-8"
        },
        withCredentials: false,
        credentials: 'same-origin',
      })
      .then(response => {  
  
        if(!response.data.sucess)
        {
          throw response.data.message;
        }
  
        if(response.data.sucess)
        {
          this.setState((prevState, props) => ({
            ...prevState,
            visibleRegisterButton: true
          }));
        }

      })
      .catch(error => {
        const message = error + "";
  
        console.log(message);
      }); 
  };

  handleRegister = event => {
    this.setState(
      {
        ...this.state,
        register: true
      }
    )
  };

  handleLogin = event => {
    event.preventDefault();
    const isValid = this.validate();

    if (isValid) {

      this.setState(
        {
          ...this.state,
          showprogress: true,
          loginError: ""
        }
      );

      this.login();
      
    }
  };

  render(){

    const { classes } = this.props;

    if(this.state.register)
    {
        if(this.state.company === "datys") return <Redirect to='/registracia/dlznik' />
        return <Redirect to='/register' />
    }

    if(this.state.auth)
    {
        return <Redirect to='/application' />
    }

    let link = (<div>
      <Link href="http://www.sherlook.sk">
        www.sherlook.sk
      </Link>
    </div>);

    let logo = <img src={logoSherlook} className="App-logo" alt="logo" />;
    if(this.state.company === "datys") 
    {
      logo = <img src={logoNajcredit} className="App-logo" alt="logo" />;
      link = (<div>
        <Link href="http://www.najcredit.sk">
          www.najcredit.sk
        </Link>
      </div>);
    }

    let typeCompanyFieldStyle = {};
    if(this.state.urlCompany !== "") typeCompanyFieldStyle = {"display": "none"};

    if(this.state.showprogress)
    {
      return(
        <div>
        <Dialog open={true}  aria-labelledby="form-dialog-title"
          BackdropProps={{
            classes: {
              root: classes.root
            }
          }}
        >
          {logo}

          <DialogContent>

            {link}

            <div>Prihlasujem do systému...</div>
            <br></br>

            <CircularProgress />

          </DialogContent>
        </Dialog>
      </div>
      )
    }

    let registerButton =  null;
    if(this.state.visibleRegisterButton) registerButton =  <Button onClick={this.handleRegister} color="primary">Registrácia</Button>

    return (

      <div>
        <Dialog open={true}  aria-labelledby="form-dialog-title"
          BackdropProps={{
            classes: {
              root: classes.root
            }
          }}
        >
          {logo}

          <DialogContent>

          {link}

          <TextField
              margin="dense"
              id="company"
              name="company"
              label="Firma"
              type="text"
              style = {typeCompanyFieldStyle}
              fullWidth
              value= {this.state.company}
              onChange={this.handleChange}
              onBlur={this.isRegisterAllowed}
            />
            <div style={{ fontSize: 12, color: "red", textAlign: "left"}}>
              {this.state.companyError}
            </div>
            <TextField
              margin="dense"
              id="username"
              name="username"
              label="Prihlasovacie meno"
              type="text"
              fullWidth
              value= {this.state.username}
              onChange={this.handleChange}
            />
            <div style={{ fontSize: 12, color: "red", textAlign: "left"}}>
              {this.state.usernameError}
            </div>
            <TextField
              margin="dense"
              id="password"
              name="password"
              label="Heslo"
              type="password"
              fullWidth
              value= {this.state.password}
              onChange={this.handleChange}
            />
            <FormControlLabel
              control={<Checkbox checked={this.state.rememberMe} onChange={this.handleChangeCheckBox}  name="rememberMe" />}
              label="zapamätaj si ma"
            />
            <div style={{ fontSize: 12, color: "red", textAlign: "left"}}>
              {this.state.passwordError}
            </div>
            <div style={{ fontSize: 12, color: "red", textAlign: "left"}}>
              {this.state.loginError}
            </div>
          </DialogContent>
          <DialogActions>
            <Grid container alignItems="flex-end" spacing={2}>
              <Grid item sm={6} xs={6}>
                {registerButton}
              </Grid>
              <Grid item sm={6} xs={6}>
                  <Button onClick={this.handleLogin} color="primary">
                    Prihlásiť do systému
                  </Button>
              </Grid>
            </Grid>
              
          </DialogActions>
        </Dialog>
      </div>
    );
  }  
}

export default withStyles(useStyles)(Login)